<template>
  <div class="business-analysis">
    <div class="box_header">
      <DataCenterHeader @selectAccountData="getAccountInfo">
        <!-- <el-button slot="header-right" size="mini" @click="handlePublish">发布作品</el-button> -->
      </DataCenterHeader>
    </div>

    <div class="content-box">
      <div class="menus">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item v-for="item in menuConfig" :key="item.index" :index="item.index">{{ item.name }}</el-menu-item>
        </el-menu>
      </div>

      <component :is="getComponents" :account="accountInfo"></component>
    </div>
  </div>
</template>

<script>
import DataCenterHeader from "@/components/dataCenter/DataCenterHeader.vue";
import ShortVideo from "@/components/dataCenter/ShortVideo.vue";
import LiveStreaming from "@/components/dataCenter/LiveStreaming.vue";
export default {
  data() {
    return {
      accountInfo: {},
      activeIndex: "1",
      menuConfig: [
        {
          index: "1",
          name: "短视频",
          component: "ShortVideo",
        },
        {
          index: "2",
          name: "直播",
          component: "LiveStreaming",
        },
      ],
    };
  },
  computed: {
    getComponents() {
      const component = this.menuConfig.find((item) => item.index == this.activeIndex);
      return component.component;
    },
  },
  components: {
    DataCenterHeader,
    ShortVideo,
    LiveStreaming,
  },
  methods: {
    getAccountInfo(accountInfo) {
      this.accountInfo = accountInfo;
    },

    // 发布作品
    handlePublish() {
      console.log("发布作品");
    },

    handleSelect(key, keyPath) {
      this.activeIndex = key;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-button {
  border: none;
  background-color: #594fee;
  color: #fff;
}
.business-analysis {
  .box-header {
  }

  .content-box {
    background-color: #fff;
    min-height: calc(100vh - 235px);
    margin-top: 20px;
    padding: 0 24px 24px 24px;
    border-radius: 8px;
    box-sizing: border-box;

    ::v-deep .el-menu--horizontal > .el-menu-item.is-active {
      border-color: #fe2c55;
    }
  }
}
</style>
