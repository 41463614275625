<template>
  <div class="live-streaming">
    <div class="header-box">
      <div class="header-title">
        <span class="title-main">数据表现</span>
        <span class="title-tip">{{ liveData.statistics_time_text || "" }}</span>
      </div>

      <div class="header-operation">
        <!-- 日期类型切换 -->
        <!-- <el-radio-group class="radio-group" v-model="params.date_type" size="small" @change="handleChangeDateType">
          <el-radio-button :label="item.label" v-for="item in dateTypeOptions" :key="item.label">{{ item.text }}</el-radio-button>
        </el-radio-group> -->

        <el-date-picker
          v-model="filterParams.filterTime"
          :clearable="false"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>

        <!-- <el-button icon="el-icon-download" @click="exportData">导出数据</el-button> -->
      </div>
    </div>

    <div class="type-buttons">
      <!-- 直播-互动-粉丝-开播 切换 -->
      <div
        :class="['button-item', { 'is-active': currentLiveTypeIndex == item.index }]"
        v-for="item in liveTypeOption"
        :key="item.index"
        @click="handleChangeLiveType(item.index)"
      >
        {{ item.label }}
      </div>
    </div>

    <div class="content-box">
      <div class="content-buttons">
        <!-- 子菜单切换 -->
        <div
          :class="['button-item', { 'is-active': item.type == currentLiveTypeChildrenIndex }]"
          v-for="item in getLiveTypeChildren"
          :key="item.id"
          @click="handleChangeLiveDataType(item.type, item.unit)"
        >
          <div class="item-label">{{ item.label }}</div>
          <div class="item-data">{{ parseFloat(item.value) }}{{ item.unit ? item.unit : "" }}</div>
        </div>
      </div>
      <div class="content-data">
        <div class="data-header">{{ getDataHeaderLabel }}</div>
        <div class="data">
          <div class="live-no-data" v-if="chartData.list.length == 0">
            <img src="../../assets/img/datacenter/business_no_data.svg" alt="" />
            <span>暂无数据</span>
          </div>

          <div class="live-chart" v-else>
            <div class="chart" ref="chart"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="additional-box" v-if="isAdditionalShow">
      <div class="additional-header">
        <span class="title-main">新增粉丝来源分析</span>
        <span class="title-tip">{{ "" }}</span>
      </div>

      <div class="additional-content">
        <div class="live-no-data">
          <img src="../../assets/img/datacenter/business_no_data.svg" alt="" />
          <span>暂无数据</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { liveChartData1, liveChartData2 } from "../../utils/mock";
export default {
  name: "LiveStreaming",

  // 组件的数据
  data() {
    return {
      filterParams: {
        filterTime: "",
      },
      currentLiveTypeIndex: "1",
      currentLiveTypeChildrenIndex: "1",
      liveTypeOption: [
        {
          index: "1",
          label: "观看",
          additional: false,
          apiFun: "dataCenterLiveWatch",
          // mockData: liveWatchJson,
          handlerFun: "getLiveWatchData",
          children: [
            {
              id: "watch_num",
              label: "观看人次",
              type: "1",
              value: 0,
            },
            {
              id: "watch_people",
              label: "观看人数",
              type: "2",
              value: 0,
            },
            {
              id: "watch_max_people",
              label: "在线人数峰值",
              type: "3",
              value: 0,
            },
            {
              id: "fans_watch_people",
              label: "粉丝观看人数",
              type: "4",
              value: 0,
            },
            {
              id: "avg_watch_duration",
              label: "平均观看时长",
              type: "5",
              value: 0,
              unit: "min",
            },
            {
              id: "fans_avg_watch_duration",
              label: "粉丝平均观看时长",
              type: "6",
              value: 0,
              unit: "min",
            },
          ],
        },
        {
          index: "2",
          label: "互动",
          additional: false,
          apiFun: "dataCenterLiveInteractive",
          // mockData: liveInteractiveJson,
          handlerFun: "getLiveInterActive",
          children: [
            {
              id: "comment_people",
              label: "评论人数",
              type: "8",
              value: 0,
            },
            {
              id: "comment_num",
              label: "评论次数",
              type: "7",
              value: 0,
            },
            {
              id: "like_num",
              label: "点赞次数",
              type: "10",
              value: 0,
            },
            {
              id: "share_num",
              label: "转发次数",
              type: "9",
              value: 0,
            },
          ],
        },
        {
          index: "3",
          label: "开播",
          additional: false,
          apiFun: "dataCenterLiveBase",
          // mockData: liveBaseJson,
          handlerFun: "getLiveBaseData",
          children: [
            {
              id: "live_duration",
              label: "开播时长",
              type: "14",
              unit: "min",
              value: 0,
            },
            {
              id: "live_num",
              label: "开播次数",
              type: "13",
              value: 0,
            },
          ],
        },
        {
          index: "4",
          label: "粉丝",
          // additional: "fans",
          additional: false,
          apiFun: "dataCenterLiveFollow",
          // mockData: liveFollowJson,
          handlerFun: "getLiveFollowData",
          children: [
            {
              id: "follow_people",
              label: "直播间新增粉丝数量",
              type: "11",
              value: 0,
            },
            {
              id: "follow_rate",
              label: "关注率",
              type: "12",
              value: 0,
              unit: "%",
            },
          ],
        },
      ],
      dateTypeOptions: [
        {
          label: "1",
          text: "昨日",
          title: "昨日数据",
        },
        {
          label: "7",
          text: "近7天",
          title: "近7天趋势",
        },
        {
          label: "30",
          text: "近30天",
          title: "近30天趋势",
        },
      ],
      params: {
        id: "",
        // date_type: "1",
        data_type: "1",
        date: "",
      },
      liveData: {},
      chartData: {
        list: [],
        name: "",
      },
      unit: "",
      resetFlag: false,
    };
  },

  props: {
    account: { type: Object, Required: true },
  },

  mounted() {
    this.getLiveData();
  },

  computed: {
    getDataHeaderLabel() {
      const dateStr = this.filterParams.filterTime.toString();
      return dateStr.replace(",", " 至 ") + "数据";
    },

    // 导出数据文件名
    getExportLabel() {
      const dateTypeObj = this.dateTypeOptions.find((item) => item.label == this.params.date_type);

      // data_type
      const liveTypeObj = this.liveTypeOption.find((item) => item.index == this.currentLiveTypeIndex);
      const dataTypeObj = liveTypeObj.children.find((item) => item.type == this.params.data_type);

      return `${dateTypeObj.text}${dataTypeObj.label}数据`;
    },

    getLiveTypeChildren() {
      const currentLiveType = this.liveTypeOption.find((item) => item.index == this.currentLiveTypeIndex);
      return currentLiveType.children;
    },

    isAdditionalShow() {
      const currentLiveType = this.liveTypeOption.find((item) => item.index == this.currentLiveTypeIndex);
      return currentLiveType.additional ? currentLiveType.additional : false;
    },

    // getChartTooltipLabel() {
    //   const currentLiveType = this.liveTypeOption.find((item) => item.index == this.currentLiveTypeIndex);
    //   const dataTypeObj = currentLiveType.children(item => item.type == this.currentLiveTypeChildrenIndex);

    //   return dataTypeObj.label;
    // },
  },

  watch: {
    "account.id": {
      handler(newVal) {
        if (!newVal) return;

        // this.initParams();
        this.params.id = newVal;
        this.resetFlag = true;
        this.initDefaultTime();
        // this.setParams(true);
      },
      deep: true,
      immediate: true,
    },

    "filterParams.filterTime": {
      handler(newVal) {
        // this.getVideoData();
        if (this.resetFlag) {
          this.resetFlag = !this.resetFlag;
          this.setParams(true);
          this.getLiveData();
        } else {
          this.setParams();
          this.handleChangeLiveType(this.currentLiveTypeIndex);
        }
      },
      deep: true,
      immediate: false,
    },
  },

  methods: {
    // 格式化日期为 yyyy-MM-dd
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    initDefaultTime() {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const oneMonthAgo = new Date(yesterday);
      oneMonthAgo.setDate(yesterday.getDate() - 30);
      this.filterParams.filterTime = [this.formatDate(oneMonthAgo), this.formatDate(yesterday)];
    },

    // 格式化数量
    formatCount(value) {
      let count = parseFloat(value);
      if (isNaN(count)) return 0;

      let negativeFlag = count < 0;
      if (negativeFlag) count = -count;

      if (count < 10000) return `${negativeFlag ? "-" : ""}${count.toString()}`;
      else return `${negativeFlag ? "-" : ""}${(count / 10000).toFixed(2)}万`;
    },

    // 获取图表数据
    getChartData() {
      if (!this.params.id) return;
      this.params.date = this.filterParams.filterTime.toString();

      this.$httpStudent.axiosGetBy(this.$api.dataCenterLiveTrend, this.params, (res) => {
        if (res.code == 200) {
          // res.data.list.forEach((item) => {
          //   // 处理特殊字符
          //   const match = item.value.match(/^(\d+)(\D*)$/);
          //   if (match) {
          //     const num = match[1];
          //     const charPart = match[2];
          //     this.charStr = charPart;
          //     item.value = num;
          //   }
          // });

          this.chartData = res.data;
          if (this.chartData.list.length > 0) this.$nextTick(() => this.setChartOption());
        } else {
          this.$message({
            type: "error",
            message: res.message,
            duration: 1000,
          });
        }
      });
    },

    // 获取直播数据
    getLiveData() {
      if (!this.params.id) return;
      this.params.date = this.filterParams.filterTime.toString();

      const liveTypeObj = this.liveTypeOption.find((item) => item.index == this.currentLiveTypeIndex);

      this.$httpStudent.axiosGetBy(this.$api[liveTypeObj.apiFun], this.params, (res) => {
        if (res.code == 200) {
          this.liveData = res.data;

          liveTypeObj.children.forEach((item) => {
            this.$set(item, "value", this.liveData[item.id]);
          });
          this.getChartData();
        } else {
          this.$message({
            type: "error",
            message: res.message,
            duration: 1000,
          });
        }
      });
    },

    // 设置图表
    setChartOption() {
      if (!this.$refs.chart) return;

      const data = this.chartData.list;
      const label = this.chartData.name;
      const chart = this.$echarts.init(this.$refs.chart);
      const options = {
        grid: {
          left: "0%",
          right: "1%",
          top: "10%",
          bottom: "5%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis", // 悬停时触发
          axisPointer: { type: "shadow" }, // 指示器类型为阴影
          formatter: (params) => {
            return `
              <div style="color: '#86909C';">${params[0].name}</div>
              <div style="color: #000;">${label}: <span style="color: ${params[0].color}; font-size: 20px;">${params[0].value}${this.unit}</span></div>
            `;
          },
        },
        legend: {
          show: false,
        },
        xAxis: {
          type: "category",
          data: data.map((item) => item.date),
          axisLabel: {
            color: "#86909C",
            formatter: function (value) {
              // 返回字符串的最后五个字符
              return value.length > 5 ? value.slice(-5) : value;
            },
          },
          axisLine: { lineStyle: { color: "#C9CDD4" } },
          splitLine: { lineStyle: { type: "dashed" } },
          axisTick: { show: false },
        },
        yAxis: {
          type: "value",
          axisLabel: { color: "86909C", formatter: `{value}${this.unit ? this.unit : ""}` },
          axisLine: { show: false, lineStyle: { color: "#C9CDD4" } },
          splitLine: { lineStyle: { type: "dashed" } },
        },
        series: [
          {
            name: this.getChartTooltipLabel,
            type: "line",
            showSymbol: false,
            smooth: true,
            areaStyle: {
              // 设置渐变色
              color: {
                type: "linear",
                x: 0, // 渐变色的起始点 x 轴位置
                y: 0, // 渐变色的起始点 y 轴位置
                x2: 0, // 渐变色的结束点 x 轴位置
                y2: 1, // 渐变色的结束点 y 轴位置
                colorStops: [
                  { offset: 0, color: "rgba(223, 240, 254, 0.2)" }, // 起始颜色
                  { offset: 1, color: "rgba(0, 0, 255, 0)" }, // 结束颜色
                ],
                global: false, // 使用局部渐变色
              },
            },
            data: data.map((item) => item.value),
            itemStyle: {
              color: "#594fee",
            },
          },
        ],
      };
      // chart.clear();
      chart.setOption(options);

      window.addEventListener("resize", () => chart.resize());
    },

    // 日期类型切换
    // handleChangeDateType() {
    //   this.getChartData();
    // },

    // 观看互动等菜单切换
    handleChangeLiveType(index) {
      this.currentLiveTypeIndex = index;

      const liveTypeObj = this.liveTypeOption.find((item) => item.index == index);
      this.currentLiveTypeChildrenIndex = liveTypeObj.children[0].type;
      this.unit = liveTypeObj.children[0].unit ? liveTypeObj.children[0].unit : "";
      this.setParams();

      this.getLiveData();
    },

    // data_type切换
    handleChangeLiveDataType(type, unit) {
      this.currentLiveTypeChildrenIndex = type;
      this.unit = unit ? unit : "";
      this.setParams();

      this.getChartData();
    },

    // 设置params
    setParams(reset = false) {
      if (reset) {
        // 菜单重置
        this.currentLiveTypeIndex = "1";
        this.currentLiveTypeChildrenIndex = "1";

        // params重置
        // this.params.date_type = "1";
        this.params.data_type = "1";
      }

      this.params.data_type = this.currentLiveTypeChildrenIndex;
    },

    // 导出数据
    exportData() {
      const exportParams = {
        id: this.params.id,
        date_type: this.params.date_type,
        data_type: this.params.data_type,
        token: localStorage.getItem("studentToken"),
      };
      const label = this.getExportLabel;

      this.$httpStudent.axiosGetBy(this.$api.dataCenterLiveExport, exportParams, (res) => {
        const blob = new Blob([res], { type: ".xlsx" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${label}.xlsx`; // 设置文件名
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.live-streaming {
  .header-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;

    .header-title {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(37, 38, 50, 0.6);
      text-align: left;
      font-style: normal;

      .title-main {
        font-size: 18px;
        color: #111;
        margin-right: 10px;
        font-weight: 600;
      }
    }

    .header-operation {
      display: flex;
      align-items: center;
      .radio-group {
        padding: 4px;
        border-radius: 4px;
        background-color: #f2f2f4;
        margin-right: 10px;

        ::v-deep .el-radio-button__orig-radio + .el-radio-button__inner {
          background-color: transparent;
          color: rgba(37, 38, 50, 0.6);
          font-size: 14px;
          font-weight: 400;
          border-color: transparent;
          box-shadow: none;
          border-radius: 4px;
          padding: 2px 24px;
        }

        ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
          background-color: #ffffff;
          color: #fe2c55;
        }

        ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner:hover {
          color: #fe2c55;
        }

        ::v-deep .el-radio-button__inner:hover {
          color: #fe2c55;
        }
      }

      ::v-deep .el-button {
        margin-left: 10px;
        padding: 6px 12px;
        background-color: #f2f2f4;
        border: none;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #777880;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

      ::v-deep .el-date-editor .el-range-input {
        background-color: transparent;
      }

      ::v-deep .el-date-editor--daterange.el-input,
      .el-date-editor--daterange.el-input__inner,
      .el-date-editor--timerange.el-input,
      .el-date-editor--timerange.el-input__inner {
        width: 250px;
      }

      ::v-deep .el-range-editor.el-input__inner {
        background-color: #f2f2f4;
        border: none;
      }
    }
  }

  .type-buttons {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
    cursor: pointer;

    .button-item {
      color: #52263299;
      background-color: #e4e4e6;
      border-radius: 4px;
      padding: 2px 8px;

      &.is-active {
        color: #fe2c55;
        background-color: #ffe8e9;
      }
    }
  }

  .content-box {
    height: 405px;
    border: 1px solid #f2f2f4;
    box-sizing: border-box;

    .content-buttons {
      display: flex;
      align-items: center;

      .button-item {
        width: 25%;
        height: 90px;
        background-color: #f9f9fa;
        box-sizing: border-box;
        border-bottom: 1px solid #f2f2f4;
        padding: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
        color: #252632;
        text-align: left;
        font-style: normal;
        cursor: pointer;

        &.is-active {
          .item-label {
            color: #fe2c55;
          }
          background-color: #fff;
          border-top: 2px solid #fe2c55;
          border-bottom: none;
        }

        .item-data {
          margin-top: 8px;
          font-size: 20px;
          font-weight: 700;
        }
        border-right: 1px solid #f2f2f4;

        // &:not(.button-item:last-of-type) {
        // }
      }
    }

    .content-data {
      height: calc(100% - 90px);
      box-sizing: border-box;
      // background-color: #111;
      padding: 16px;
      display: flex;
      flex-direction: column;

      .data-header {
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: 600;
        font-family: PingFang SC, PingFang SC;
        color: #252632;
        flex-grow: 0;
      }

      .data {
        flex: 1;

        .live-no-data {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 32px;

          img {
            width: 100px;
            height: 100px;
            object-fit: cover;
          }

          span {
            font-size: 16px;
            font-weight: 600;
            color: #25263299;
            font-family: PingFang SC, PingFang SC;
          }
        }

        .live-chart {
          height: 100%;
          width: 100%;

          .chart {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  .additional-box {
    margin-top: 24px;
    .additional-header {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(37, 38, 50, 0.6);
      text-align: left;
      font-style: normal;

      .title-main {
        font-size: 18px;
        color: #111;
        margin-right: 10px;
        font-weight: 600;
      }
    }

    .additional-content {
      height: 200px;
      box-sizing: border-box;
      padding: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      // background-color: #111;

      .live-no-data {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;

        img {
          width: 100px;
          height: 100px;
          object-fit: cover;
        }

        span {
          font-size: 16px;
          font-weight: 600;
          color: #25263299;
          font-family: PingFang SC, PingFang SC;
        }
      }
    }
  }
}
</style>
